import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Row,
  Divider,
  Input,
  Form,
  Button,
  Card,
  InputNumber,
  Checkbox,
  Modal,
  Select,
  Layout,
  DatePicker,
} from "antd";
import Cleave from "cleave.js/react";
import moment from "moment";
import RequestHandler from "util/RequestHandler";
import { defaultValidateMessages } from "../forms/Helper";
import { footerText } from "../util/config";
import PosSelectableInstallmentTable from "../routes/PosCollection/PosSelectableInstallmentTable";
import PosCollectionAmountSummary from "../routes/PosCollection/PosCollectionAmountSummary";
import { ColumnHelper } from "../util/TableHelper";
import PhoneInput from "react-phone-input-2";

const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const PaymentPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [firmNameInputDisabled, setFirmNameInputDisabled] = useState(false);
  const [creditCardType, setCreditCardType] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [description, setDescription] = useState("");
  const [amountString, setAmountString] = useState("");
  const [form] = Form.useForm();
  const [paymentModal, setPaymentModal] = useState({
    visible: false,
    source: null,
  });
  const [availableInstallments, setAvailableInstallments] = useState({
    loading: false,
    data: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  });
  const { Footer } = Layout;
  const iframeRef = useRef();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [
    defaultValueLanguageOptionSelect,
    setDefaultValueLanguageOptionSelect,
  ] = useState("tr");
  const languageOptionList = [
    { code: "tr", key: "1", value: 0 },
    { code: "en", key: "2", value: 1 },
  ];

  const [confidentialAgreement, setconfidentialAgreement] = useState(
    "/gizlilik-ve-guvenlik-politikasi.pdf"
  );
  const [salesAgreement, setSalesAgreement] = useState("/tuketici-haklari.pdf");

  const [ozanIframe, setOzanIframe] = useState(false);
  const [useAdditionalInformation, setUseAdditionalInformation] =
    useState(false);

  useEffect(() => {
    getAvailableProviders();
  }, []);

  const [commissionOptions, setCommissionOptions] = useState({
    loading: true,
    data: {
      useCommissionsForAvailableInstallment: false,
      useFirmGroupCodeForMaxInstallment: false,
      isSelectableInstallmentTable: false,
      commissions: [],
    },
  });

  const [activeAmount, setActiveAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0.0);
  const [radioValue, setRadioValue] = useState();
  const [paymentRequestId, setPaymentRequestId] = useState();
  const [activeCommissionRate, setActiveCommissionRate] = useState(0.0);
  const [isValidityDateExpired, setIsValidityDateExpired] = useState(false);
  const [isUseB2BCurrencyExChange, setIsUseB2BCurrencyExChange] =
    useState(false);
  const [currencyExchangeRates, setCurrencyExchangeRates] = useState({
    data: {
      usdAmount: null,
      euroAmount: null,
      tlAmount: null,
    },
  });
  const [currencyCode, setCurrencyCode] = useState("TRY");
  const [emailRequired, setEmailRequired] = useState(false);
  const [paymentMethodList, setPaymentMethodList] = useState({
    loading: true,
    data: [],
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [isHavePaymentSetId, setIsHavePaymentSetId] = useState(false);
  const [
    isUseB2BViewCurrencyExchangeRate,
    setIsUseB2BViewCurrencyExchangeRate,
  ] = useState(false);

  const [viewCurrencyExchangeRates, setViewCurrencyExchangeRates] = useState({
    data: {
      usd: null,
      euro: null,
    },
  });

  const [b2bFilePath, setB2bFilePath] = useState();
  const [tenantScreenSettings, setTenantScreenSettings] = useState({
    b2BShowCampaignButton: true,
    b2BCustomerInformationVisibility: true,
    b2BMobilePhoneVisibility: false,
    b2BTcknVisibility: false,
  });
  const [
    b2BIsUseAdditionalFieldForPolicy,
    setB2BIsUseAdditionalFieldForPolicy,
  ] = useState(false);

  useEffect(() => {
    getPaymentRequestInfo();
  }, []);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
    }
  }, [props.data]);

  const getPaymentRequestInfo = () => {
    RequestHandler.post(
      `/PaymentRequest/GetByPaymentRequestId`,
      {
        PaymentRequestString: props.location.search.split("d=")[1],
      },
      false
    ).then((response) => {
      setIsPaid(response.isPaid);
      setAmountString(response.amountString);
      setDescription(response.description);
      setTenantName(response.tenantName);
      setUseAdditionalInformation(response.isPosTransactionUseAdditionalField);
      getCommissionOptions(
        response.paymentGuid,
        response.firmId,
        response.paymentSetId
      );
      setActiveAmount(response.amount);
      setPaymentRequestId(response.paymentGuid);
      setCurrencyCode(response.currencyCode);
      setB2bFilePath(response.b2BFilePath);
      getTenantScreenSettings(response.tenantId);
      setB2BIsUseAdditionalFieldForPolicy(
        response.b2BIsUseAdditionalFieldForPolicy
      );
      if (response.confidentialityAgreementPath) {
        setconfidentialAgreement(response.confidentialityAgreementPath);
      }

      if (response.salesAgreementPath) {
        setSalesAgreement(response.salesAgreementPath);
      }

      if (response.isUseB2BCurrencyExChange) {
        setIsUseB2BCurrencyExChange(response.isUseB2BCurrencyExChange);
        setCurrencyCode(response.currencyCode);
        handleAmountChange(
          response.amount,
          response.tenantId,
          response.currencyCode
        );
      }

      if (response.b2BViewCurrencyExchangeRate) {
        setIsUseB2BViewCurrencyExchangeRate(
          response.b2BViewCurrencyExchangeRate
        );

        RequestHandler.get(
          `/PosCollection/GetCurrencies?tenantId=${response.tenantId}`
        ).then((currencyResponse) => {
          if (currencyResponse) {
            setViewCurrencyExchangeRates({
              data: {
                usd: currencyResponse?.find((item) => item.code === "USD")
                  ?.dailyValue,
                euro: currencyResponse?.find((item) => item.code === "EUR")
                  ?.dailyValue,
              },
            });
          }
        });
      }

      form.setFieldsValue({
        ...form.getFieldValue(),
        customerInformation: response.firmName,
      });
      response.firmName?.trim().length > 0 && setFirmNameInputDisabled(true);

      let languageOption = languageOptionList.find(
        (x) => x.value == response.b2BPayWithLinkDefaultLanguageOption
      );
      if (languageOption !== undefined || languageOption !== null) {
        changeLanguage(languageOption.code);
        setDefaultValueLanguageOptionSelect(languageOption.code);
      } else {
        changeLanguage("tr");
        setDefaultValueLanguageOptionSelect("tr");
      }

      if (response.validityDate !== null) {
        const today = moment();
        const validityDate = moment(response.validityDate);

        if (validityDate.isBefore(today, "day")) {
          setIsValidityDateExpired(true);
        }
      }

      if (response.paymentSetId) {
        setIsHavePaymentSetId(true);
      }

      if (response.isPosTransactionUseAdditionalField) {
        RequestHandler.get(
          `/PayWithoutLogin/GetPaymentMethods?tenantId=${response.tenantId}`
        ).then((response) => {
          setPaymentMethodList({
            data: response.data,
            loading: false,
          });
        });
      }
      if (response.b2BIsUseAdditionalFieldForPolicy) {
        form.setFieldsValue({ policyDate: moment() });
      }
    });
  };

  const getAvailableProviders = () => {};
  const onFinish = (values) => {
    let expireMonth, expireYear;
    if (values.expiration) {
      [expireMonth, expireYear] = values.expiration.split("/");
    }

    var bankIdCommissionIdWithInstallment = values.bankIdInstallment.split("/");
    values.bankId = bankIdCommissionIdWithInstallment[0];
    values.installmentCount = bankIdCommissionIdWithInstallment[3];
    values.cardType = bankIdCommissionIdWithInstallment[2];
    values.posCollectionCommissionDefinitionId =
      bankIdCommissionIdWithInstallment[1];

    RequestHandler.post(
      `/PosCollection/SalesRequestWithCode`,
      {
        paymentCode: props.location.search.split("d=")[1],
        holderName: values.holderName,
        cardNumber: values.cardNumber,
        cvv: values.cvv,
        expireMonth: expireMonth,
        expireYear: expireYear,
        customerInformation: values.customerInformation,
        description: values.description,
        tckn: values.tckn,
        contractNumber: values.contractNumber,
        branchCode: values.branchCode,
        memberNumber: values.memberNumber,
        bankId: values.bankId,
        installmentCount: values.installmentCount,
        netAmount: activeAmount,
        amount: payableAmount,
        cardType: values.cardType,
        posCollectionCommissionDefinitionId:
          values.posCollectionCommissionDefinitionId,
        customerEmail: values.email,
        paymentMethod: selectedPaymentMethod?.name,
        paymentSetId: selectedPaymentMethod?.paymentSetId,
        mobilePhone: values.mobilePhone,
        policyDate: values.policyDate?.format("YYYY-MM-DD"),
      },
      false
    ).then((response) => {
      setLoading(false);
      if (response.success) {
        if (response.data?.startsWith("https")) {
          setOzanIframe(true);
        }

        //fill html
        setPaymentModal({ visible: true, source: response.data });
        form.resetFields();
        form.setFieldsValue({
          bankId: values.bankId,
          firmId: values.firmId,
        });

        if (props.onSuccess) props.onSuccess();
      }
    });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const renderCardInfoInputs = () => {
    return (
      <>
        {tenantScreenSettings.b2BCustomerInformationVisibility && (
          <Col
            span={24}
            hidden={!tenantScreenSettings.b2BCustomerInformationVisibility}
          >
            <Form.Item
              label={t("customerInformation")}
              name="customerInformation"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={t("customerInformation")}
                disabled={firmNameInputDisabled}
              />
            </Form.Item>
          </Col>
        )}

        {tenantScreenSettings.b2BMobilePhoneVisibility && (
          <Col
            span={12}
            hidden={!tenantScreenSettings.b2BMobilePhoneVisibility}
          >
            <Form.Item
              label={t("mobilePhone")}
              name="mobilePhone"
              rules={[
                {
                  required:
                    defaultValueLanguageOptionSelect === "tr" &&
                    tenantScreenSettings.b2BMobilePhoneVisibility,
                  min: 12,
                  message: t("mobilePhoneRequiredMessage"),
                },
              ]}
            >
              <PhoneInput
                inputStyle={{ width: "100%" }}
                country="tr"
                masks={{ tr: "(...) ...-..-.." }}
              />
            </Form.Item>
          </Col>
        )}

        {useAdditionalInformation || b2BIsUseAdditionalFieldForPolicy ? (
          <>
            <Col span={12}>
              <Form.Item
                label={t("tckn")}
                name="tckn"
                rules={[
                  {
                    required: true,
                    min: 11,
                    message: t("tcknError"),
                  },
                ]}
              >
                <Input
                  placeholder={t("tckn")}
                  type="number"
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  maxLength="11"
                  onInput={maxLengthCheck}
                />
              </Form.Item>
            </Col>

            {useAdditionalInformation && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={t("contractNumber")}
                    name="contractNumber"
                    rules={[
                      {
                        required: true,
                        min: 6,
                        message: t("contractNumberError"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("contractNumber")}
                      type="number"
                      maxLength="6"
                      onInput={handleNumberInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("branchCode")}
                    name="branchCode"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("branchCode")}
                      maxLength="50"
                      onInput={maxLengthCheck}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("memberNumber")} name="memberNumber">
                    <Input placeholder={t("memberNumber")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("paymentMethod")}
                    name="paymentMethod"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t("paymentMethod")}
                      onChange={(value, option) => {
                        setSelectedPaymentMethod(option);
                      }}
                    >
                      {paymentMethodList.data?.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          name={item.name}
                          paymentSetId={item.paymentSetId}
                        >
                          {`${item.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            {b2BIsUseAdditionalFieldForPolicy && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={t("policyNumber")}
                    name="contractNumber"
                    rules={[
                      {
                        required: true,
                        max: 50,
                        message: t("policyNumberRequired"),
                      },
                    ]}
                  >
                    <Input placeholder={t("policyNumber")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("policyIssueDate")}
                    name="policyDate"
                    rules={[{ required: true, message: t("policyDateRequired") }]}
                  >
                    <DatePicker format="DD.MM.YYYY" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </>
            )}
          </>
        ) : tenantScreenSettings.b2BTcknVisibility ? (
          // useAdditionalInformation false ve tenantScreenSettings.b2BTcknVisibility true olduğunda sadece TCKN göster
          <>
            <Col span={12}>
              <Form.Item
                label={t("tckn")}
                name="tckn"
                rules={[
                  {
                    min: 11,
                    message: t("tcknError"),
                  },
                ]}
              >
                <Input
                  placeholder={t("tckn")}
                  type="number"
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  maxLength="11"
                  onInput={maxLengthCheck}
                />
              </Form.Item>
            </Col>
          </>
        ) : null}

        <Col span={24}>
          <Form.Item
            label={t("nameOnCard")}
            name="holderName"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("nameSurname")} />
          </Form.Item>
          <Form.Item
            label={t("cardNumber")}
            name="cardNumber"
            valuePropName="rawValue"
            rules={[
              { required: true },
              {
                min: 15,
                max: 16,
              },
            ]}
          >
            <Cleave
              className="ant-input"
              placeholder="0000 0000 0000 0000"
              options={{
                creditCard: true,
                onCreditCardTypeChanged: (type) => setCreditCardType(type),
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("expiryDate")}
            name="expiration"
            rules={[{ required: true }]}
          >
            <Cleave
              className="ant-input"
              placeholder="AA/YY"
              options={{ date: true, datePattern: ["m", "y"] }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t("securityCode")}
            name="cvv"
            rules={[{ required: true }]}
          >
            <Cleave
              className="ant-input"
              placeholder="CVV"
              options={{
                blocks: [4],
                numericOnly: true,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("description")} name="description">
            <TextArea rows={4} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: emailRequired, type: "email" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </>
    );
  };

  const afterPayment = () => {
    setPaymentModal({ ...paymentModal, visible: false });
    window.location.reload();
  };

  const changeLanguage = (e) => {
    setDefaultValueLanguageOptionSelect(e);
    i18n.changeLanguage(e);
  };

  const onChangeRadio = (radioVal, values) => {
    setRadioValue(radioVal);
    setFieldsValue(values);
  };

  const setFieldsValue = (values) => {
    const currentValues = form.getFieldsValue();
    const newValues = { ...currentValues, ...values };
    form.setFieldsValue(newValues);

    onFormValuesChange(values, newValues);
  };

  const onFormValuesChange = (changedValues, allValues) => {
    var activeCommissions = commissionOptions.data.commissions.find(
      (x) => x.bankId === allValues.bankId && !x.isVisaMasterCard
    );

    if (allValues.bankIdInstallment) {
      var bankIdCommissionIdWithInstallment =
        allValues.bankIdInstallment.split("/");

      activeCommissions = commissionOptions.data.commissions.find(
        (x) =>
          x.bankId === allValues.bankId &&
          x.id === bankIdCommissionIdWithInstallment[1] &&
          !x.isVisaMasterCard
      );
    }

    if (
      bankIdCommissionIdWithInstallment &&
      bankIdCommissionIdWithInstallment[0] === "856"
    ) {
      setEmailRequired(true);
    } else {
      setEmailRequired(false);
    }

    if (!activeCommissions) {
      setPayableAmount(
        calculatePayableAmount(activeAmount, 0, allValues.bankId, false)
      );
      setActiveCommissionRate(0);
      return;
    }
    const commissionRate =
      activeCommissions["commissionRate" + allValues.installmentCount];
    if (!(activeAmount > 0 && commissionRate >= 0)) {
      setPayableAmount(
        calculatePayableAmount(activeAmount, 0, allValues.bankId, false)
      );
      setActiveCommissionRate(commissionRate);
      return;
    }

    setPayableAmount(
      calculatePayableAmount(
        activeAmount,
        commissionRate,
        allValues.bankId,
        activeCommissions["isCommissionFromDealer"]
      )
    );
    setActiveCommissionRate(commissionRate);
  };

  const calculatePayableAmount = (
    amount,
    commissionRate,
    bankId,
    isCommissionFromDealer
  ) => {
    const { useCommissionsForAvailableInstallment } = commissionOptions.data;
    let payableAmount = amount;

    if (commissionRate === 0 && bankId === undefined) {
      return payableAmount;
    }
    if (useCommissionsForAvailableInstallment) {
      payableAmount = amount + (amount / 100) * commissionRate;
    }

    if (isCommissionFromDealer && useCommissionsForAvailableInstallment) {
      payableAmount = (amount / (100 - commissionRate)) * 100;
    }

    if (bankId == 856) {
      payableAmount = amount / ((100 - commissionRate) / 100);
    }

    return payableAmount;
  };

  const getCommissionOptions = (paymentGuid, firmId, paymentSetId) => {
    let queryString = "&paymentGuid=" + paymentGuid;
    if (firmId) {
      queryString += "&firmId=" + firmId;
    }
    if (paymentSetId) {
      queryString += "&paymentSetId=" + paymentSetId;
    }
    RequestHandler.get(
      `/PaymentRequest/CommissionOptions?requestForNoPayment=true${queryString}`
    ).then((response) => {
      if (response.success) {
        setCommissionOptions({
          data: response.data,
          loading: false,
        });
      } else {
        setCommissionOptions({ ...commissionOptions, loading: false });
      }
    });
  };

  const handleAmountChange = (amount, tenantId, currencyCode) => {
    RequestHandler.post(
      `/PayWithoutLogin/GetPosCollectionTransactionExchangeAmounts`,
      {
        amount: amount,
        tenantId: tenantId,
        currencyCode: currencyCode,
      },
      false
    ).then((response) => {
      if (response?.success) {
        setCurrencyExchangeRates({
          data: {
            usdAmount: response.data.usdAmount,
            euroAmount: response.data.euroAmount,
            tlAmount: response.data.tlAmount,
          },
        });
      }
    });
  };

  useEffect(() => {
    if (isHavePaymentSetId || !useAdditionalInformation) {
      return;
    }

    getCommissionOptions(
      paymentRequestId,
      undefined,
      selectedPaymentMethod?.paymentSetId
    );

    clearRadioValue();
  }, [selectedPaymentMethod]);

  const clearRadioValue = () => {
    setRadioValue(null);
    form.setFieldsValue({ bankIdInstallment: null });
  };

  const getTenantScreenSettings = (tenantId) => {
    RequestHandler.get(
      `/PaymentRequest/TenantScreenSettings?tenantId=${tenantId}&screenId=5`
    ).then((response) => {
      if (response.success) {
        setTenantScreenSettings(response.data);
      }
    });
  };

  const handleNumberInput = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    e.target.value = sanitizedValue;

    if (sanitizedValue > e.target.maxLength) {
      e.target.value = sanitizedValue.slice(0, e.target.maxLength);
    }
  };

  return (
    <>
      <Row id="titleArea">
        <Col
          xs={12}
          md={12}
          lg={20}
          style={{ textAlign: "right", marginTop: "10px" }}
        >
          <p>{t("languageOptionText")}</p>
        </Col>
        <Col xs={12} md={12} lg={4} style={{ marginTop: "5px" }}>
          <Select
            id="languageSelect"
            onChange={changeLanguage}
            value={defaultValueLanguageOptionSelect}
            size="middle"
          >
            <Select.Option key={1} value="tr" i18nIsDynamicList>
              Türkçe
            </Select.Option>
            <Select.Option key={2} value="en">
              English
            </Select.Option>
          </Select>
        </Col>
      </Row>

      <Divider style={{ margin: "12px 0" }} />

      <Row id="paymentArea" justify="center">
        <Col md={16} lg={16} xl={12}>
          <Card title={t("paymentForm")}>
            {isPaid ? (
              <div style={{ textAlign: "center" }}>
                <h2>{t("successfulPaymentMessage")}</h2>
              </div>
            ) : isValidityDateExpired ? (
              <div style={{ textAlign: "center" }}>
                <h2>{t("paymentValidityDateExpired")}</h2>
              </div>
            ) : (
              <Form
                {...formItemLayout}
                validateMessages={defaultValidateMessages}
                form={form}
                name="PaymentForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelAlign="left"
              >
                <Row>
                  <Col span={24}>
                    <div style={{ textAlign: "center" }}>
                      {t("amount")}: <strong>{amountString}</strong>
                      <div style={{ marginTop: "4px" }}>
                        {currencyExchangeRates.data.usdAmount &&
                        currencyExchangeRates.data.euroAmount ? (
                          <div style={{ color: "#0047ca" }}>
                            {currencyExchangeRates.data.usdAmount} /{" "}
                            {currencyExchangeRates.data.euroAmount}
                          </div>
                        ) : currencyExchangeRates.data.euroAmount ? (
                          <div style={{ color: "#0047ca" }}>
                            {currencyExchangeRates.data.euroAmount}
                          </div>
                        ) : currencyExchangeRates.data.usdAmount ? (
                          <div style={{ color: "#0047ca" }}>
                            {currencyExchangeRates.data.usdAmount}
                          </div>
                        ) : null}
                      </div>
                      {currencyExchangeRates.data.tlAmount && (
                        <div style={{ marginTop: "4px" }}>
                          <div style={{ color: "#0047ca" }}>
                            {currencyExchangeRates.data.tlAmount}
                          </div>
                        </div>
                      )}
                    </div>

                    <div style={{ textAlign: "center" }}>
                      {isUseB2BViewCurrencyExchangeRate ? (
                        <div style={{ color: "#0047ca" }}>
                          {t("usdRate") +
                            ColumnHelper.renderDecimal(
                              viewCurrencyExchangeRates.data?.usd
                            )}
                        </div>
                      ) : null}
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {isUseB2BViewCurrencyExchangeRate ? (
                        <div style={{ color: "#0047ca" }}>
                          {t("euroRate") +
                            ColumnHelper.renderDecimal(
                              viewCurrencyExchangeRates.data?.euro
                            )}
                        </div>
                      ) : null}
                    </div>

                    <div style={{ textAlign: "center" }}>
                      {t("description")}: <strong>{description}</strong>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {t("paymentRecipient")}: <strong>{tenantName}</strong>
                    </div>
                  </Col>

                  {renderCardInfoInputs()}
                  <Col span={24}>
                    <PosSelectableInstallmentTable
                      key={JSON.stringify(commissionOptions.data)}
                      data={commissionOptions.data}
                      amount={activeAmount}
                      loading={commissionOptions.loading}
                      onChange={onChangeRadio}
                      radioValue={radioValue}
                      filePath={b2bFilePath}
                      campaignButtonVisibility={
                        tenantScreenSettings.b2BShowCampaignButton
                      }
                    />
                  </Col>

                  <Col span={24}>
                    <PosCollectionAmountSummary
                      payableAmount={payableAmount}
                      activeAmount={activeAmount}
                      colSpan={20}
                      commissionRate={activeCommissionRate}
                    />
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: "Sözleşmeleri kabul etmeniz gerekmektedir.",
                        },
                      ]}
                    >
                      <Checkbox>
                        <a href={confidentialAgreement} target="_blank">
                          {t("privacy")}
                        </a>{" "}
                        ve{" "}
                        <a href={salesAgreement} target="_blank">
                          {t("consumerRights")}
                        </a>{" "}
                        {t("readContract")}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setLoading(true)}
                      loading={loading}
                    >
                      {t("pay")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
      <Footer>
        <div className="gx-layout-footer-content">
          {Screen.screen === 0 ? (
            <>
              <span>{footerText}</span>
              <img
                src={require("../assets/images/paymentSymbols.png")}
                alt="PaymentSymbols"
                title="PaymentSymbols"
                style={{
                  width: "100%",
                  maxWidth: "450px",
                  marginLeft: "550px",
                }}
              />
            </>
          ) : null}
        </div>
      </Footer>

      <Modal
        id="paymentModal"
        centered
        visible={paymentModal.visible}
        onCancel={() => afterPayment()}
        destroyOnClose={false}
        maskClosable={false}
        keyboard={false}
        width={600}
        footer={null}
      >
        {ozanIframe ? (
          <iframe
            id="paymentFrame"
            ref={iframeRef}
            style={{ width: "100%", border: 0 }}
            src={paymentModal.source}
            scrolling="auto"
            onLoad={() => {
              const iframe = iframeRef.current;
              iframe.style.height = iframe.scrollWidth + 150 + "px";
            }}
          />
        ) : (
          <iframe
            id="paymentFrame"
            ref={iframeRef}
            style={{ width: "100%", border: 0 }}
            srcDoc={paymentModal.source}
            sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
            scrolling="auto"
            onLoad={() => {
              const iframe = iframeRef.current;
              iframe.style.height = iframe.scrollWidth + 150 + "px";
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default PaymentPage;
